<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <label for="profile-image" class="custom-file">
        <img v-if="image" :src="image" alt="profile image" />
        <img
          src="@/assets/img/profile-image-default.svg"
          v-else
          alt="profile image"
        />
        <span>
          <i class="fas fa-plus"></i> {{ $t("labels.upload_profile_photo") }}
        </span>
      </label>
      <input
        type="file"
        @change="handleImage"
        accept="image/png, image/jpg, image/jpeg"
        id="profile-image"
        name="img"
      />
      <div class="validation-error-message has-text-centered">
        {{ validation.firstError("image") }}
      </div>
      <div
        class="validation-error-message has-text-centered"
        v-if="imageSizeError"
      >
        {{ $t("errors.max_size_3mb") }}
      </div>
    </div>
    <div class="column is-12" :class="{ error: validation.hasError('name') }">
      <input type="text" placeholder="John Doe" v-model="name" class="input" />
      <div class="validation-error-message">
        {{ validation.firstError("name") }}
      </div>
    </div>
    <div
      class="column is-12"
      :class="{ error: validation.hasError('birthday') }"
    >
      <datetime
        :disabled="birthdayDisabled"
        v-model="birthday"
        class="theme-superlive"
        input-class="input"
        :placeholder="$t('labels.birthday')"
        format="dd.MM.yyyy"
        :max-datetime="new Date(eighteenYearsAgo).toISOString()"
      >
        <template slot="button-cancel">
          {{ $t("labels.cancel") }}
        </template>
        <template slot="button-confirm">
          {{ $t("labels.confirm") }}
        </template>
      </datetime>
      <div class="validation-error-message">
        {{ validation.firstError("birthday") }}
      </div>
    </div>
    <div class="column is-12">
      <div class="gender-container">
        <label for="male" class="custom-check">
          <input
            type="radio"
            id="male"
            v-model="gender"
            name="gender"
            value="1"
            :disabled="genderDisabled"
          />
          <span class="checkmark"></span>
          <span class="custom-check-label" v-html="$t('labels.male')"></span>
        </label>
        <label for="female" class="custom-check">
          <input
            type="radio"
            id="female"
            v-model="gender"
            name="gender"
            value="2"
            :disabled="genderDisabled"
          />
          <span class="checkmark"></span>
          <span class="custom-check-label" v-html="$t('labels.female')"></span>
        </label>
      </div>
      <div
        class="validation-error-message has-text-centered"
        v-if="validation.hasError('gender')"
      >
        {{ $t("errors.gender") }}
      </div>
    </div>
    <div class="column is-12">
      <button
        class="confirm-onboarding special-button primary-button"
        :disabled="loading"
        @click="confirmOnboarding"
      >
        {{ $t("labels.confirm") }}
      </button>
      <div class="lds-ellipsis" v-if="loading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div class="validation-error-message" v-if="onboardingError">
        {{ onboardingError }}
      </div>
    </div>
    <div class="column is-12">
      <div
        class="aggrement-text"
        v-html="$t('login_page.aggrement_label')"
      ></div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "./Onboarding.scss";
</style>
<script>
"use strict";
import { mapActions } from "vuex";
import SimpleVueValidation from "simple-vue-validator";
import { Datetime } from "vue-datetime";

import "@/assets/flags/flags.css";

const endpoints = require("../../../api/endpoints");
const Validator = SimpleVueValidation.Validator;

export default {
  name: "Onboarding",
  data: () => {
    return {
      email: "",
      loading: false,
      onboardingError: null,
      birthday: null,
      name: null,
      gender: null,
      image: "",
      ownProfile: null,
      eighteenYearsAgo: (() => {
        const date = new Date();
        date.setFullYear(date.getFullYear() - 18);
        return date;
      })(),
      imageSizeError: false,
      genderDisabled: false,
      birthdayDisabled: false,
      minimumImageLength: 113,
    };
  },
  components: {
    datetime: Datetime,
  },
  mounted() {
    let that = this;
    endpoints.own_profile(this.$axios).then(function (ownProfileResponse) {
      ownProfileResponse = ownProfileResponse.data;
      that.name =
        ownProfileResponse.user.name.indexOf("#") < 0
          ? ownProfileResponse.user.name
          : null;

      let newBirthday = new Date(ownProfileResponse.user.birthday);
      that.birthday = ownProfileResponse.user.birthday
        ? newBirthday.toISOString()
        : new Date(that.eighteenYearsAgo).toISOString();

      that.gender = ownProfileResponse.user.gender
        ? ownProfileResponse.user.gender
        : null;
      if (ownProfileResponse.user.gender) {
        that.genderDisabled = true;
      }
      if (ownProfileResponse.user.birthday) {
        that.birthdayDisabled = true;
      }

      if (
        ownProfileResponse.user.profile_images != null &&
        ownProfileResponse.user.profile_images.length > 0
      ) {
        that.image = ownProfileResponse.user.profile_images[0].url;
      }
    });
  },
  validators: {
    name: function (value) {
      return Validator.value(value).required();
    },
    image: function (value) {
      return Validator.value(value);
    },
    birthday: function (value) {
      if (!this.birthdayDisabled) {
        return Validator.value(value).required();
      }
      return Validator.value(value);
    },
    gender: function (value) {
      if (!this.genderDisabled) {
        return Validator.value(value).required();
      }
      return Validator.value(value);
    },
  },
  methods: {
    ...mapActions("client", ["getOwnProfile"]),
    confirmOnboarding() {
      this.onboardingError = false;
      let that = this;
      this.$validate().then((success) => {
        if (success) {
          if (this.image.length > this.minimumImageLength) {
            that.loading = true;
            endpoints
              .upload_image(that.$axios, {
                profile_image: that.image.split(",")[1],
              })
              .then((imageUploadResponse) => {
                if (imageUploadResponse.error) {
                  that.onboardingError = imageUploadResponse.error.message;
                  that.loading = false;
                } else {
                  this.updateProfile(imageUploadResponse.data.id);
                }
              });
          } else {
            this.updateProfile();
          }
        }
      });
    },
    handleImage(e) {
      this.imageSizeError = false;
      let selectedImage = e.target.files[0];
      if (selectedImage.size / 1000 / 1000 > 3) {
        this.imageSizeError = true;
      } else {
        this.createBase64String(selectedImage);
      }
    },
    createBase64String(fileObject) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    updateProfile(imageAdress = null) {
      this.loading = true;
      let birthday = new Date(this.birthday);
      birthday = birthday.valueOf();
      let profileUpdateData = {
        name: this.name,
      };
      if (imageAdress !== null) {
        profileUpdateData.image_ids = [imageAdress];
      }
      if (!this.genderDisabled) {
        profileUpdateData.gender = this.gender;
      }
      if (!this.birthdayDisabled) {
        profileUpdateData.birthday = birthday;
      }
      endpoints
        .update_user(this.$axios, profileUpdateData)
        .then((profileUpdateResponse) => {
          profileUpdateResponse = profileUpdateResponse.data;
          if (profileUpdateResponse.error) {
            this.onboardingError = profileUpdateResponse.error.message;
            this.loading = false;
          } else {
            this.getOwnProfile();
            setTimeout(() => {
              this.$router.push("/");
            }, 400);
          }
        });
    },
  },
};
</script>
