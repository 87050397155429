import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index.js";
import Home from "@/views/Home.vue";
import LoginView from "@/views/Login/LoginView.vue";
import OnboardingView from "@/views/Login/OnboardingView.vue";
import TwitterCallback from "@/components/Login/TwitterCallback/TwitterCallback.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";
import TermsOfService from "@/views/TermsOfService.vue";
import Contact from "@/views/Contact.vue";
import Settings from "@/views/Settings.vue";
import Payment from "@/components/Payment/Payment.vue";
import LivestreamView from "@/views/Livestream/LivestreamView.vue";
import ProfileView from "@/views/Profile/ProfileView.vue";
import FollowersView from "@/views/Follow/UserFollowers/FollowersView.vue";
import FollowingsView from "@/views/Follow/UserFollowings/FollowingsView.vue";
import FollowingsStreamView from "@/views/Follow/FollowingsStream/FollowingsStreamView.vue";
import MessageView from "@/views/Message/MessageView.vue";
import LeaderBoardView from "@/views/LeaderBoard/LeaderBoardView.vue";
import CoinSelectPayermax from "@/components/Payment/Payermax/CoinSelectPayermax.vue";
import PayermaxCheckout from "@/components/Payment/Payermax/PayermaxCheckout.vue";
import PayermaxCreditCard from "@/components/Payment/Payermax/PayermaxCreditCard.vue";
import PayermaxGetEmail from "@/components/Payment/Payermax/PayermaxGetEmail.vue";
import PaymentComplete from "@/components/Payment/Payermax/PaymentComplete.vue";
import NotificationsView from "@/views/Notifications/NotificationsView.vue";
import MyLevelView from "@/views/MyLevel/MyLevelView.vue";
import CoinReseller from "@/components/Payment/CoinReseller/CoinReseller.vue";
import DeleteAccountInfo from "@/views/DeleteAccountInfo.vue";
import Payermax from "@/components/Payment/Payermax/Payermax.vue";
import CrazySale from "@/components/Payment/CrazySale/CrazySale.vue";
import PayPalCapture from "@/views/PayPalCapture.vue";
import Safety from "@/views/safety/safety.vue";
import AboutOurSafetyCenter from "@/views/safety/components/about-our-safety-center.vue";
import OnlineSocialAwareness from "@/views/safety/components/online-social-awareness.vue";
import OurSafetyTools from "@/views/safety/components/our-safety-tools.vue";
import MakeAReport from "@/views/safety/components/make-a-report.vue";
import ForLawEnforcement from "@/views/safety/components/for-law-enforcement.vue";
import SafetyTips from "@/views/safety/components/safety-tips.vue";
import OurCommunityGuidelines from "@/views/safety/components/our-community-guidelines.vue";
import Faqs from "@/views/safety/components/faqs.vue";
import ChildSafety from "@/views/safety/components/ChildSafety.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "LoginView",
    component: LoginView,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/onboarding",
    name: "OnboardingView",
    component: OnboardingView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/twitter_callback",
    name: "TwitterCallback",
    component: TwitterCallback,
    meta: {
      requiresUnauth: true,
    },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-of-service",
    name: "TermsOfService",
    component: TermsOfService,
  },
  {
    path: "/safety",
    name: "SafetyCenter",
    component: Safety,
    children: [
      {
        path: "/",
        name: "SafetyCenter",
        component: AboutOurSafetyCenter,
      },
      {
        path: "safety-tips",
        name: "SafetyTips",
        component: SafetyTips,
      },
      {
        path: "community-guidelines",
        name: "CommunityGuidelines",
        component: OurCommunityGuidelines,
      },
      {
        path: "child-safety",
        name: "ChildSafety",
        component: ChildSafety,
      },
      {
        path: "social-awareness",
        name: "SocialAwareness",
        component: OnlineSocialAwareness,
      },
      {
        path: "safety-tools",
        name: "SafetyTools",
        component: OurSafetyTools,
      },
      {
        path: "make-report",
        name: "MakeReport",
        component: MakeAReport,
      },
      {
        path: "law-enforcement",
        name: "LawEncforcement",
        component: ForLawEnforcement,
      },
      {
        path: "faqs",
        name: "SafetyFaqs",
        component: Faqs,
      },
    ],
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
  {
    path: "/checkout",
    name: "Payment",
    component: Payment,
  },
  {
    path: "/stream/:streamInfo",
    name: "Livestream",
    component: LivestreamView,
  },
  {
    path: "/profile/:profileId",
    name: "Profile",
    component: ProfileView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/followers/:profileId",
    name: "Followers",
    component: FollowersView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/followings/:profileId",
    name: "Followings",
    component: FollowingsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/followings-stream/",
    name: "FollowingsStream",
    component: FollowingsStreamView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/messages",
    name: "Messages",
    component: MessageView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/leaderboard/",
    name: "LeaderBoardView",
    component: LeaderBoardView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payermax",
    name: "Payermax",
    component: Payermax,
  },
  {
    path: "/coin-select-payermax",
    name: "CoinSelectPayermax",
    component: CoinSelectPayermax,
  },
  {
    path: "/payermax/checkout",
    name: "PayermaxCheckout",
    component: PayermaxCheckout,
  },
  {
    path: "/payermax/coin-reseller",
    name: "CoinReseller",
    component: CoinReseller,
  },
  {
    path: "/payermax/credit-card",
    name: "PayermaxCreditCard",
    component: PayermaxCreditCard,
  },
  {
    path: "/payermax/payment-complete",
    name: "PayermaxPaymentComplete",
    component: PaymentComplete,
  },
  {
    path: "/payermax/email",
    name: "PayermaxGetEmail",
    component: PayermaxGetEmail,
  },
  {
    path: "/crazy_sale",
    name: "CrazySale",
    component: CrazySale,
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: NotificationsView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/my-level",
    name: "MyLevel",
    component: MyLevelView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/delete-account",
    name: "DeleteAccountInfo",
    component: DeleteAccountInfo,
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/paypal/capture/:idempotence",
    name: "PayPalCapture",
    component: PayPalCapture,
    meta: {
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  // FOR PAYMENT ONLY REDIRECT
  if (
    process.env.VUE_APP_PAYMENT_ONLY_REDIRECT != null &&
    (to.name == null ||
      to.fullPath == null ||
      to.fullPath == "/" ||
      !(
        (to.fullPath != null && to.fullPath.startsWith("/payermax")) ||
        to.name.startsWith("Payermax")
      ))
  ) {
    window.location.href = process.env.VUE_APP_PAYMENT_ONLY_REDIRECT;
  }

  // FOR HYBRID PAGES
  store.commit("client/setShowJoinNow", false);
  store.commit("client/setShowBuyCoinsModal", false);

  // FOR AUTH URL
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters["client/isLoggedIn"]) {
      if (store.getters["client/ownProfile"].extra_information_required) {
        if (to.path !== "/onboarding") {
          next("/onboarding");
          return;
        }
      }
      next();
      return;
    }
    next("/login");
  } else {
    if (to.path === "/") {
      if (store.getters["client/isLoggedIn"]) {
        if (store.getters["client/ownProfile"].extra_information_required) {
          next("/onboarding");
          return;
        }
        next();
        return;
      } else {
        next("/login");
        return;
      }
    }
  }

  // FOR UNAUTH URL
  if (to.matched.some((record) => record.meta.requiresUnauth)) {
    if (store.getters["client/isLoggedIn"]) {
      next("/");
      return;
    }
    next();
  } else {
    next();
  }
});

const originalPush = router.push;
router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
